:root {
  --primary-font-family: Nunito, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --secondary-font-family: Shippori Mincho B1, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --theme-bordures: #d5dadf;
  --theme-primary: #2c465d;
  --theme-on-primary: #fff;
  --theme-secondary: #e95e53;
  --theme-on-secondary: #fff;
  --theme-background: #fbfbfb;
  --theme-on-background: #2c465d;
  --theme-background-secondary: #fff;
  --theme-background-secondary-50: #ffffff80;
  --theme-background-secondary-75: #ffffffc0;
  --theme-surface: #ebe9d7;
  --theme-surface-50: #ebe9d780;
  --theme-on-surface: #2c465d;
  --theme-on-surface-title: #444a4a;
  --theme-surface-secondary: #d1e6e6;
  --theme-on-surface-secondary: #2c465d;
  --theme-box-shadow-color: #00000029;
  --theme-background-modal: #2c465d66;
  --page-margin-horizontal: 0rem;
  --content-width: 70%;
  --header-bandeau-height: 18.75rem;
  --header-search-bar-height: 7.5rem;
  --carte-z-index: 1500;
  --largeur-maximal: 120rem;
}

html {
  scroll-padding-top: var(--header-search-bar-height);
  height: 100%;
  font-size: 16px;
}

@media (width >= 768px) and (device-width >= 1280px) and (-webkit-device-pixel-ratio >= 1.25) {
  html {
    font-size: 14px;
  }
}

@media (width >= 768px) and (device-width >= 1280px) and (-webkit-device-pixel-ratio >= 1.5) {
  html {
    font-size: 12px;
  }
}

@media (width <= 768px) {
  :root {
    --header-bandeau-height: 10em;
    --header-search-bar-height: 5.5em;
  }
}

body {
  background-color: var(--theme-background);
  color: var(--theme-on-background);
  font: normal normal normal 1rem var(--primary-font-family);
  height: 100%;
  margin: 0;
}

svg {
  fill: currentColor;
}

a {
  color: currentColor;
}

.bg-image {
  position: relative;
}

@media screen {
  .bg-image:after {
    content: "";
    background-color: var(--theme-surface);
    opacity: .5;
    -webkit-mask: var(--url-bg-image) no-repeat;
    -webkit-mask-image: var(--url-bg-image);
    mask-image: var(--url-bg-image);
    z-index: -1000;
    width: 38rem;
    height: 38rem;
    position: absolute;
    top: 2rem;
    right: 1rem;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

#container {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

#content, footer {
  width: min(100vw, var(--largeur-maximal));
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

#content {
  flex-grow: 1;
  min-height: 100%;
}

footer {
  background-color: var(--theme-primary);
  font: normal normal normal 1rem / 1.5rem var(--primary-font-family);
  color: var(--theme-on-primary);
  flex-direction: column;
  row-gap: 2rem;
  padding: 3em 3em 1em;
  display: flex;
}

footer .footer-body {
  gap: 3rem;
  display: flex;
}

footer .infos-region {
  flex-direction: column;
  flex: 3;
  row-gap: .5rem;
  display: flex;
}

footer .title {
  font: normal normal 500 1.375rem / 2rem var(--secondary-font-family);
  letter-spacing: 0;
  margin-bottom: 1rem;
}

footer .infos-region .title {
  margin-bottom: .5rem;
}

footer .bottom-link-group .title, footer .region {
  text-transform: uppercase;
}

footer .link-with-icon {
  align-items: center;
  column-gap: .5rem;
  display: flex;
}

footer .adresse {
  white-space: pre-line;
}

footer .copyright {
  border-top: var(--theme-on-primary) solid .0625rem;
  text-align: center;
  font: normal normal normal 1rem / 1.5rem var(--primary-font-family);
  grid-area: copyright;
  padding: 0 10em;
}

footer .bottom-link-group {
  flex: 2;
}

footer .bottom-link-group .title {
  font-size: 1.5em;
}

footer .bottom-link-group ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer a {
  color: var(--theme-on-primary);
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .mail, footer .aide, footer .mention-legales, footer .politique-confidentialite, footer .accessibilite {
  font-weight: bold;
}

footer svg {
  width: 1.5rem;
}

@media (width <= 1279px) {
  footer .footer-body {
    flex-direction: column;
    display: flex;
  }

  footer .copyright {
    padding: 0 var(--page-margin-horizontal);
  }
}

.image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-container img, .image-container svg {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.image-container .popup {
  z-index: 500;
  background-color: #ffffffe6;
  min-width: 15rem;
  max-width: 50%;
  padding: 1rem;
  display: none;
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.image-container .popup h3 {
  font: normal normal bold 1.125rem / 1.625rem var(--secondary-font-family);
  margin: 0;
}

@media (width <= 1279px) {
  .image-container .popup {
    max-width: 80vw;
  }
}

.image-container .popup-wrap .popup:hover, .image-container .popup-wrap .btn-image:hover + .popup {
  display: block;
}

.image-container .popup-wrap ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.image-container .btn-image {
  --btn-image-background-color: var(--theme-primary);
  --btn-image-color: var(--theme-on-primary);
  z-index: 510;
  cursor: pointer;
  background-color: var(--btn-image-background-color);
  color: var(--btn-image-color);
  border: none;
  border-radius: 3rem;
  padding: 1.1em;
  position: absolute;
  bottom: 1.5em;
  right: 1.5em;
}

.image-container .btn-image:hover {
  background-color: var(--btn-image-color);
  color: var(--btn-image-background-color);
}

.image-container .btn-image svg {
  width: 2rem;
}

.image-container .popup div:first-child {
  padding-top: 1em;
}

.image-container .popup a {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
  color: var(--theme-primary);
  align-items: center;
  margin: .5rem 4.375rem 0 0;
  text-decoration: none;
  display: flex;
}

.image-container .popup a:hover {
  text-decoration: underline;
}

.image-container .popup a .icon-lien-dossier {
  margin-left: .5em;
}

.image-container .popup.show {
  display: block;
}

.image-container .popup svg {
  width: 2rem;
  padding-left: .5em;
}

.image-indispo-filigrane {
  opacity: .3;
}

.illustration-probleme-diffusion {
  color: var(--theme-on-background);
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.illustration-probleme-diffusion .texte {
  background-color: var(--theme-primary);
  color: var(--theme-on-primary);
  padding: 1em;
  box-shadow: .3rem .3rem .3rem #00000080;
}

.image-indispo {
  position: relative;
}

details > summary {
  cursor: pointer;
  align-items: center;
  list-style: none;
  display: flex;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after, details[open] summary:after {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask: var(--url-icon-summary-close) no-repeat;
  -webkit-mask-image: var(--url-icon-summary-close);
  mask-image: var(--url-icon-summary-close);
  content: "";
  background-color: currentColor;
  width: 2rem;
  height: 2rem;
}

details[open] summary:after {
  -webkit-mask-image: var(--url-icon-summary-open);
  -webkit-mask-image: var(--url-icon-summary-open);
  mask-image: var(--url-icon-summary-open);
}

.lien-auteur {
  display: inline-block;
  position: relative;
}

.lien-auteur-popup {
  z-index: 10;
  padding: 2rem 0 0;
  display: none;
  position: absolute;
  top: calc(100% - 2rem);
}

.lien-auteur-popup-container {
  font: normal normal normal 1rem / 1.25rem var(--primary-font-family);
  background-color: var(--theme-background-secondary);
  color: var(--theme-primary);
  box-shadow: 0 .2rem .375rem var(--theme-box-shadow-color);
  border-radius: .625rem;
  flex-direction: column;
  gap: .5rem;
  min-width: 19rem;
  max-width: 31rem;
  padding: 1rem 1rem 2rem;
  display: flex;
}

.lien-auteur-popup-title {
  font: normal normal bold 1rem / 1.375rem var(--primary-font-family);
}

.lien-auteur-popup:hover, .lien-auteur-link:hover ~ .lien-auteur-popup {
  display: block;
}

.lien-auteur a.lien-auteur-link:not(:hover):not(:active) {
  text-decoration: underline;
}

.list-auteur {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media print {
  @page {
    margin: 1cm;
    size: A4;
  }

  body {
    background-color: initial;
  }

  footer {
    display: none;
  }
}

/*# sourceMappingURL=common.0ac63deb.css.map */
